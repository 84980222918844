<template>
  <div>
    <b-row>
      <b-col class="text-right">
        <b-button type="button" variant="info" class="mb-2 mx-2" v-b-modal.modal-xl>
          <feather-icon icon="YoutubeIcon"/>
          {{ $t('training.btnViewVDO') }}
        </b-button>
        <b-button type="button" variant="primary" :to="{name: 'pageTrainingExam'}" class="mb-2">
          <feather-icon icon="FileTextIcon"/>
          {{ $t('training.btnExam') }}
        </b-button>
      </b-col>
    </b-row>

    <b-overlay :show="isShowLoading">
      <b-card :title="$t('training.list')">
        <!-- table -->
        <vue-good-table
            mode="remote"
            :columns="tableData.columns"
            :rows="tableData.rows"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            :totalRows="tableData.totalRecords"
            :isLoading.sync="tableData.isLoading"
            :pagination-options="{ enabled: true }"
        >
          <template
              slot="table-row"
              slot-scope="props"
          >
            <div v-if="props.column.field === 'trainingScore'" class="text-nowrap text-center">
              {{ props.row.trainingScore + 0 }}/{{ props.row.trainingTotalScore }}
            </div>
            <div v-else-if="props.column.field === 'trainingIsPass'" class="text-nowrap text-center">
              <b-badge :variant="props.row.trainingIsPass === 'Y' ? 'success' : 'danger'">
                {{ props.row.trainingIsPass | isPass }}
              </b-badge>
            </div>
            <div v-else>{{ props.formattedRow[props.column.field] }}</div>
          </template>

          <!-- pagination -->
          <template
              slot="pagination-bottom"
              slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t('general.tableShowing') }} 1 {{ $t('general.tableTo') }}
            </span>
                <b-form-select
                    v-model="tableData.pageLength"
                    :options="tableData.pageLengthOption"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> {{ $t('general.tableOf') }} {{ props.total }} {{ $t('general.tableEntries') }} </span>
              </div>
              <div>
                <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="tableData.pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </b-overlay>

    <b-modal id="modal-xl" centered size="lg" :title="$t('training.vdoTitle')"
             no-close-on-backdrop
             no-close-on-esc
             ok-only
    >
      <b-embed type="iframe" aspect="16by9" src="https://www.youtube.com/embed/jmlHiWmTsVk?rel=0" allowfullscreen/>

      <template #modal-footer="{ ok, hide }">
        <b-button variant="danger" @click="hide()">
          {{ $t('general.btnClose') }}
        </b-button>
        <b-button variant="primary" :to="{name: 'pageTrainingExam'}">
          {{ $t('training.btnExam') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {BRow, BCol, BCard, BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BButton, BForm, BOverlay, BModal, BCardText, BEmbed, BBadge} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import tableMixins from "@/mixins/tableMixins"
import {PositionService, TrainingService} from "@/services"

const positionService = new PositionService()
const trainingService = new TrainingService()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BPagination,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BOverlay,
    VueGoodTable,
    BModal,
    BCardText,
    BEmbed,
    BBadge,
  },
  mixins: [tableMixins],
  created() {
    this.tableData.columns = [
      {
        label: this.$t('training.date'),
        field: 'trainingDatetime',
        width: '20%',
        sortable: false
      }, {
        label: this.$t('training.score'),
        field: 'trainingScore',
        width: '20%',
        sortable: false
      }, {
        label: this.$t('training.result'),
        field: 'trainingIsPass',
        width: '20%',
        sortable: false
      },
    ]

    if(this.userData.userGroupId === 5) {
      this.tableData.columns.splice(1, 0, {
        label: this.$t('training.resultName'),
        field: 'trainingName',
        width: '20%',
        sortable: false
      })
    }


    this.listData()
  },
  data: () => ({
    isShowLoading: true,
    pk: '',
    userData: JSON.parse(localStorage.getItem('userData')),
    search: {
      positionName: '',
    },
  }),
  computed: {},
  watch: {
    tableChange() {
      this.listData()
    }
  },
  filters: {
    isPass(value) {
      return value === 'Y' ? 'ผ่าน' : 'ไม่ผ่าน'
    }
  },
  methods: {
    listData() {
      this.getTableQueryString()
      trainingService.listData(this.tableQueryString).then(queryResult => {
        let {isSuccess, data} = queryResult
        if (isSuccess) {
          this.tableData.rows = data.results
          this.tableData.totalRecords = data.totalRow
        } else {
          this.$store.commit('main/setToastError', data)
        }
        this.isShowLoading = false
      })
    },
    searchData() {
      this.onColumnFilter(this.search)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

